import { combineReducers } from "@reduxjs/toolkit";
import { authReducer } from "./auth/auth.slice";
import { inputValueReducers } from "./slice/dataValues";
import { locationReducer } from "./slice/state/locationReducer";
const rootReducer = combineReducers({
  auth: authReducer,
  schemaValues: inputValueReducers,
  location: locationReducer,
});

export { rootReducer };
