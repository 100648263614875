import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  first_name: Yup.string()
    .required("First name  required")
    .min(3, "First name  too short")
    .matches(/^\S*$/, "Name can't have spaces"),

  last_name: Yup.string()
    .required("Last name  required")
    .min(3, "Last name is too short")
    .matches(/^\S*$/, "Name can't have spaces"),

  email: Yup.string().required("Email  required").email("Invalid email format"),

  phone_number: Yup.string()
    .required("Phone number required")
    .min(9, "Phone number cant be less than 11 digits"),

  linkedInUrl: Yup.string()
    .required("LinkedIn profile required")
    .matches(
      /^(https?:\/\/)?([\w]+\.)?linkedin\.com\/(in|pub|profile)\/[^\s/]+\/?$/i,
      "Invalid LinkedIn URL"
    ),

  relevantSkill: Yup.string().required("Relevant skill required"),
  yearOfRelevant: Yup.number()
    .required("Year of relevance  required")
    .max(50, "Please provide a realistic year"),
  interest: Yup.string().required("Position of interest required"),
});
