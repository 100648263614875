import ContentLayout from "../Layouts/ContentLayout";
import IndexComponent from "./components/indexComponent";

const Home = () => {
  return (
    <ContentLayout>
      <IndexComponent />
    </ContentLayout>
  );
};

export default Home;
