import { createSlice } from "@reduxjs/toolkit";

const dataSlice = createSlice({
  name: "schemaValues",
  initialState: {
    storedValues: {},
  },
  reducers: {
    updateInputValues: (state, action) => {
      state.storedValues = action.payload;
    },
  },
});

export const { updateInputValues } = dataSlice.actions;
export const inputValueReducers = dataSlice.reducer;
