import React, { FC } from "react";

import {
  Box,
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
  Textarea,
} from "@chakra-ui/react";
const InputInterface = ({
  className,
  placeholder,
  type,
  label,
  onChange,
  name,
  isInvalid,
  isError,
  ...props
}: any) => {
  return (
    <>
      <Box my={"1.5em"} {...props}>
        <FormControl isInvalid={isInvalid}>
          <FormLabel
            className={className ? className : "asterisk"}
            sx={{
              fontWeight: "500",
              color: "#344054 ",
              fontSize: "16.2px",
            }}
          >
            {label}
          </FormLabel>
          <Input
            type={type}
            placeholder={placeholder}
            variant={""}
            border={"1px solid #EDEDED"}
            py={"1.5em"}
            boxShadow={"0px 1px 2px rgba(16, 24, 40, 0.05)"}
            borderRadius={"8px"}
            onChange={onChange}
            name={name}
          />

          <FormErrorMessage>
            <FormErrorIcon />
            {isError}
          </FormErrorMessage>
        </FormControl>
      </Box>
    </>
  );
};

type Textbox = {
  name: string;
  label: string;
  placeholder: string;
  onChange?: () => void;
  isError?: boolean;
  isInvalid?: boolean;
  count?: any;
};

const TextAreaBox: FC<Textbox> = ({
  name,
  label,
  onChange,
  isError,
  isInvalid,
  placeholder,
  count,
}) => {
  return (
    <FormControl isInvalid={isInvalid}>
      <FormLabel>{label}</FormLabel>
      <Textarea onChange={onChange} placeholder={placeholder} name={name} />
      <FormErrorMessage>
        <FormErrorIcon /> {isError}
      </FormErrorMessage>

      <Text textAlign={"right"} fontSize={"13px"} color={"#344054"} my={".6em"}>
        {count}
      </Text>
    </FormControl>
  );
};

export { InputInterface, TextAreaBox };
